// GlobalStyle.jsx

import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    color: black;
    margin: 0;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  }

  body::-webkit-scrollbar {
    display: none;

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    }
`;


export default GlobalStyle;