import styled from "styled-components";

export const LogoBox = styled.div`
  display: inline-block;
  padding: 5px;
  margin-bottom: 5px;
`;

export const FooterArea = styled.footer`
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

export const Info = styled.section`
  @media screen and (max-width: 800px) {
    position: relative;
  }
`;
export const FirstParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #333333;
  span {
    display: block;
    font-size: 0.7rem;
  }
`;
