import {Button, Form, FormInstance, Input, Space} from 'antd';
import React, {Component} from 'react';
import {css} from "styled-components";
import {NavigateFunction} from "react-router/dist/lib/hooks";
// import {appStore} from "./AppStore";
import {withRouter} from "../common/UtilCompo";
import {WrapMessageAPI} from "../common/HocMessage";
// import icon_logo from "../../asset/medinomi_logo_blue.png";
import styled from "styled-components";
import {apollo_client} from "../common/ApolloHelpper";
import {gql} from "@apollo/client";
// import medifun_logo from "../../asset/메디펀 로고.png";

// import "../common/styled-components"

const Warapper = styled.div`
    min-height: 100%;
    position: relative;
`;


const LogoLayout = styled.div`
    padding: 1em;
`;

const LogoImage = styled.img`
    width: 100%;
    height: auto;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2em;
`;

const Btn = styled.div`
    text-align: center;
`;

const WhiteBox = styled.div`
    /* 윤곽선 4면 전부 그림자 */
    display: flex;
    justify-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
    padding: 1.5rem;
    width: 360px;
    background: white;
    border-radius: 10px;
    align-items: center;
`
const FormLayout = styled.div`
    padding: 0 3rem 3rem 3rem;
`

const SubText = styled.p`
    font-size: 1em;
    margin-bottom: 1.5em;
    font-weight: bold;
    color: #202442;
    text-align: center;
`

const Contents = styled.div`
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    background-color: #000000;
    position: absolute;
    bottom: 0;
`

const FooterContents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
`

const FooterLogo = styled.img`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
`
const TextGroup = styled.div`
    margin: 1em;
    display: flex;
    flex-direction: column;
    align-items: start;
`

const Address = styled.div`
    display: flex;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const CopyRight = styled.p`
    font-size: 0.3em;
    display: inline;
`

const Place = styled.p`
    font-size: 0.5em;
    display: inline-block;
`


class Login extends Component<{ navigate: NavigateFunction, title?: string } & any, any> {
    login = async (username: string, password: string) => {
        let that = this;
        console.log(username, password);
        try {
            let promise = await apollo_client.query({
                query: gql`
                    query login($username:String!,$password:String!){
                        login(username:$username,password:$password) {
                            principal
                            credentials
                            authenticated
                            details
                        }
                    }
                `
                , variables: {
                    username,
                    password
                }
                , fetchPolicy: "no-cache"
            });

            let token = promise.data.login.credentials
            console.log(token);
            sessionStorage.setItem('auth_token', token);

            /*let token = await appStore.login(id, password);
            if (appStore.hasAdmin == true) {
                that.props.navigate("/patrol2"); //관지자 랜딩페이지
                return;
            }*/
            this.props.messageApi?.success("로그인되었습니다.")
            that.props.navigate("/admin"); //일반유저 랜딩페이지

        } catch (e) {
            this.props.messageApi?.error(`로그인에 실패했습니다.`);
            this.props.messageApi?.error(`${e}`);
        }
    }

    /*login2 = async (id: string, password: string) => {

        let that = this;
        console.log(id, password);

        try {
            let {token, roles} = await appStore.login2(id, password);
            // let token = await Auth.login(id, password);
            // console.log(JSON.stringify(token))
            // console.log(JSON.stringify(token.data))
            // console.log(JSON.stringify(token.data.login))
            // this.props.messageApi?.error(`${token.data.login}`);
            if (roles.includes('READ_BLOCKCHAIN') == true) {
                that.props.navigate("/blockchain"); //관지자 랜딩페이지
                return;
            }

            if (roles.includes('ADMIN') == true) {
                that.props.navigate("/patrol2"); //관지자 랜딩페이지
                return;
            }


            if (roles.includes('T1') == true) {
                that.props.navigate("/dashboard1"); //관지자 랜딩페이지
                return;
            }

            if (roles.includes('T2') == true) {
                that.props.navigate("/dashboard2"); //관지자 랜딩페이지
                return;
            }


            // that.props.navigate("/"); //일반유저 랜딩페이지
        } catch (e) {
            this.props.messageApi?.error(`로그인에 실패했습니다.`);
            this.props.messageApi?.error(`${e}`);
        }
    }*/


    form!: FormInstance;


    render() {
        return (
            <div>
                <div css={css`
                    height: 100vh;
                    background: #eaecef`}>
                    {/*<div>
                        <p>M.T.S Medical Trigger System 관리시스템 로그인</p>
                    </div>*/}
                    <Warapper>
                        <Contents>
                            <WhiteBox>
                                <FormLayout>
                                    {/*<LogoImage src={icon_logo}/>*/}
                                    <Form
                                        // initialValues={{id:"admin",password:"1234"}}
                                        ref={(ref: FormInstance) => this.form = ref}>
                                        <Form.Item name={"id"}>
                                            <Input placeholder={"ID"} onPressEnter={() => {

                                            }
                                            }/>
                                        </Form.Item>
                                        <Form.Item name={"password"}>
                                            <Input placeholder={"Password"} type={"password"} onPressEnter={() => {
                                                let fieldsValue = this.form.getFieldsValue();
                                                this.login(fieldsValue.id, fieldsValue.password);
                                            }
                                            }/>
                                        </Form.Item>
                                        <Btn>
                                            <Space direction="vertical" style={{width: '100%'}}>
                                                <Button style={{width: '100%', background: "black"}} type="primary"
                                                        onClick={event => {
                                                            let fieldsValue = this.form.getFieldsValue();
                                                            this.login(fieldsValue.id, fieldsValue.password);
                                                        }}>login</Button>
                                            </Space>
                                        </Btn>
                                    </Form>
                                </FormLayout>
                            </WhiteBox>
                        </Contents>
                        <Footer>
                            <FooterContents>
                                {/* <div css={css`width: 120px;
                                    margin-left: 1em`}>
                                    <FooterLogo src={medifun_logo}/>
                                </div>*/}
                                <Address>
                                    <TextGroup>
                                        <Place>
                                            +82. 507.1390 .9493 hello@medifun.co.kr<br/>
                                            [BITC] 부산광역시 남구 문현금융로 40 BIFC 8층<br/>
                                            [MITC] 부산광역시 남구 신선로 428, 8호관 327<br/>
                                            [BITC Office] 8F_BIFC, 40, Munhyeongeumyung-ro, Nam-gu, Busan, Republic of
                                            Korea<br/>
                                            [MediORO Dept.] 428, Sinseon-ro, Nam-gu, Busan, Republic of Korea</Place>
                                        <CopyRight>© by MediNomi. Powered and secured by MEDIFUN</CopyRight>
                                    </TextGroup>
                                </Address>
                            </FooterContents>
                        </Footer>
                    </Warapper>
                </div>
            </div>
        );
    }
}

// withRouter()
export default withRouter(WrapMessageAPI(Login));
