import GlobalStyle from "./components/styled/GlobalStyles";
import React from 'react';
import UserRequestForm from "./UserRequestForm";
import UserInfo from "./UserInfo";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import Nav from "../src/components/Nav";
import Login from "./components/login/Login";
import DownloadTest from "./DownloadTest";
import UserInfoPage from "./UserInfoPage";
// import theme from "./Theme/theme";

const Wrapper = styled.div`
    background: #e6ebff;
    display: flex;
    align-items: center;
    justify-content: center;
`;


function App() {

    return (
        <BrowserRouter>
            {/*<ThemeProvider theme={theme}>*/}
            <Nav/>
            <GlobalStyle/>
            <Wrapper>
                <Routes>
                    <Route path="/" element={<UserRequestForm/>}/>
                    <Route path="/admin" element={<UserInfoPage/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/download" element={<DownloadTest/>}/>
                    {/*old page*/}
                    {/*<Route path="/admin" element={<UserInfo />}/>*/}
                </Routes>
            </Wrapper>
            {/*</ThemeProvider>*/}
        </BrowserRouter>
    )
}

export default App;