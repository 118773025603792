import {useState} from "react";
import {Link} from "react-router-dom";
import StyledNav, {
    DesktopMenu,
} from "./styled/Nav.styled";
import logo from '../assets/logo.png';

const Nav = () => {
    return (
        <StyledNav>
            <DesktopMenu>
                <a href="https://www.mediprofile.info/" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="메디펀 로고" width={180} height={60}/>
                </a>
                {/*<a href="https://www.mediprofile.info/" target="_blank" rel="noopener noreferrer">*/}
                {/*    <p>메디프로필 소개</p>*/}
                {/*</a>*/}
                {/*<a href="https://www.mediprofile.info/profilemanual/" target="_blank" rel="noopener noreferrer">*/}
                {/*    <p>사용방법</p>*/}
                {/*</a>*/}
            </DesktopMenu>
        </StyledNav>
    );
};

export default Nav;