import * as S from './styled/Footer.styled';
import insta from '../assets/insta.svg';
import facebook from '../assets/facebook.svg';
import youtube from '../assets/youtube.svg';
import styled from "styled-components";


export function FooterLayout() {
    return (
        <S.FooterArea>
            <S.Info>
                <S.FirstParagraph>
                    <S.LogoBox>
                        <a href="https://www.instagram.com/medi__oro/?hl=ko/" target="_blank" rel="noopener noreferrer">
                          <img src={insta} alt="인스타 로고" />
                        </a>
                    </S.LogoBox>
                    <S.LogoBox>
                        <a href="https://www.facebook.com/people/%EB%A9%94%EB%94%94%EC%98%A4%EB%A1%9C/100063912794912/" target="_blank" rel="noopener noreferrer">
                            <img src={facebook} alt="페이스북 로고" />
                        </a>
                    </S.LogoBox>
                    <S.LogoBox>
                        <a href="https://www.youtube.com/channel/UCow-yUBHHjDBHbkQvs5dVOQ/" target="_blank" rel="noopener noreferrer">
                            <img src={youtube} alt="유튜브 로고" />
                        </a>
                    </S.LogoBox>

                    <span>부산광역시 남구 문현금융로40 ​부산국제금융센터​
부산블록체인기술혁신지원센터(BITC) (주)메디펀</span>
                    <span>&nbsp;</span>
                    <span>COPYRIGHT@2024 | Medifun.Inc</span>
                </S.FirstParagraph>
            </S.Info>

        </S.FooterArea>
    );
}