import {
    EditableProTable,
    ProCard,
    ProColumns,
} from "@ant-design/pro-components";
import {Button, Form, Input, notification,} from "antd";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {EntityRequestInfo} from "../../UserInfoPage";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import Search from "antd/es/input/Search";

const {TextArea} = Input;

const CardContainer = styled(ProCard)`
    text-align: center;
    height: 75vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
`;

const TableContainer = styled.div`
    flex-grow: 1;
    max-height: 70vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;


const HeaderContainer = styled.div`
    display: flex;
    width: 68vw;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
`;

const UserCountText = styled.div`
    font-size: 14px; /* 텍스트 크기 조절 */
    text-align: left;

    span {
        font-weight: bold;
    }
`;

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
`;
const ResetButton = styled(Button)`
    margin-left: 8px;
`;
const FormWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    padding: 1vw 2vw;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const FormColumn = styled.div`
    flex: 1;
    min-width: 300px; /* 최소 너비를 설정하여 레이아웃이 깨지지 않도록 합니다. */
`;

const FormGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
`;


const InputGroup = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const InputField = styled(Input)`
    flex: 1; // 가로로 확장 가능하게 설정
`;


const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        notification.success({
            message: '복사 성공!',
            placement: 'top',
            duration: 1,
            style: {width: 150},
            closeIcon: null,
        });
    } catch (err) {
        console.error('복사에 실패했습니다!', err);
    }
};

const downloadAllFiles = async (record: EntityRequestInfo) => {
    if (!record || !record.files) return;
    const zip = new JSZip();
    for (const file of record.files) {
        console.log(`다운로드 완료: ${file.fileName} from ${file.downloadLink}`);
        try {
            const response = await fetch(file.downloadLink);
            if (!response.ok) {
                throw new Error(`다운로드 실패 ${file.fileName}: ${response.statusText}`);
            }
            const blob = await response.blob();
            zip.file(file.fileName, blob);
        } catch (error) {
            console.error(`다운로드 실패: ${file.fileName}`, error);
        }
    }
    const content = await zip.generateAsync({type: "blob"});
    saveAs(content, "all_files.zip");
};

const CustomFormItem = ({label, value, onCopy}: { label: string, value: string, onCopy: () => void }) => (
    <Form.Item label={label} labelCol={{style: {fontWeight: 'bold'}}}>
        <FormGroup>
            <InputGroup>
                <InputField value={value} readOnly/>
                <Button onClick={onCopy} style={{marginLeft: '8px'}}>복사</Button>
            </InputGroup>
        </FormGroup>
    </Form.Item>
);

type TableComponentProps<T extends EntityRequestInfo> = {
    title: string;
    columns: ProColumns<T>[];
    dataSource: T[];
    setDataSource: React.Dispatch<React.SetStateAction<T[]>>;
    showAddButton?: boolean;
    enableSearch?: boolean;
    requestFunction?: (params: any, sort: any, filter: any) => Promise<{ data: T[]; success: boolean }>;
    setRangePickerValue?: React.Dispatch<React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>>
    showDateButtonGroup?: boolean;
    expandedRowKeys?: React.Key[];
    handleExpand: (id: number) => void
    rowRefs: React.MutableRefObject<Map<number, HTMLDivElement>>
    setExpandedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
    handleSearch?: (value: string) => void
    setSearchKeyword: React.Dispatch<React.SetStateAction<string>>
    searchKeyword: string
};

const TableComponent = <T extends EntityRequestInfo>({
                                                         columns,
                                                         dataSource,
                                                         enableSearch = false,
                                                         requestFunction,
                                                         expandedRowKeys,
                                                         handleExpand,
                                                         rowRefs,
                                                         setExpandedRowKeys,
                                                         handleSearch,
                                                         setSearchKeyword,
                                                         searchKeyword
                                                     }: TableComponentProps<T>) => {

    return (
        <CardContainer layout="default">
            <TableContainer>
                <HeaderContainer>
                    <UserCountText>
                        <span>{dataSource.length}</span>명의 사용자가 MediProfile을 사용하고 있습니다.
                    </UserCountText>
                    <SearchContainer>
                        <Search
                            placeholder="검색어를 입력하세요"
                            onSearch={handleSearch}
                            style={{width: 300}}
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            allowClear
                        />
                        <ResetButton onClick={() => setSearchKeyword("")}>초기화</ResetButton>
                    </SearchContainer>
                </HeaderContainer>
                <EditableProTable<T>
                    columns={[...columns]}
                    value={dataSource}
                    rowKey={"id"}
                    recordCreatorProps={false}
                    search={
                        enableSearch
                            ? {labelWidth: "auto", defaultCollapsed: true}
                            : false
                    }
                    pagination={{
                        pageSize: 10, pageSizeOptions: ['10', '20', '30', '50'], position: ['bottomCenter'],
                        onChange: (page, pageSize) => {
                            // 페이지네이션 이동 시 상세보기 닫기 및 스크롤 맨 위로 이동
                            setExpandedRowKeys([]);
                            const tableContainer = document.querySelector('.ant-table-body');
                            if (tableContainer) {
                                tableContainer.scrollTo({top: 0, behavior: 'smooth'});
                            }
                        },
                    }}
                    bordered
                    request={requestFunction}
                    components={{
                        body: {
                            row: ({children, ...restProps}: { children: React.ReactNode; 'data-row-key': number }) => {
                                const rowId = restProps['data-row-key'];
                                const ref = (node: HTMLTableRowElement | null) => {
                                    if (node) {
                                        rowRefs.current.set(rowId, node);
                                    }
                                };
                                return (
                                    <tr {...restProps} ref={ref}>
                                        {children}
                                    </tr>
                                );
                            }
                        }

                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <FormWrapper>
                                <FormColumn>
                                    <Form layout="vertical" initialValues={{remember: true}}>
                                        <Form.Item labelCol={{style: {fontWeight: "bold"}}}>
                                            <FormGroup>
                                                <CustomFormItem
                                                    label="성명"
                                                    value={record.koreanName}
                                                    onCopy={() => copyToClipboard(record.koreanName)}
                                                />
                                                <CustomFormItem
                                                    label="영문"
                                                    value={record.englishName}
                                                    onCopy={() => copyToClipboard(record.englishName)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <CustomFormItem
                                                    label="휴대전화"
                                                    value={record.phone}
                                                    onCopy={() => copyToClipboard(record.phone)}
                                                />
                                                <CustomFormItem
                                                    label="이메일"
                                                    value={record.email}
                                                    onCopy={() => copyToClipboard(record.email)}
                                                />
                                            </FormGroup>
                                        </Form.Item>

                                        <Form.Item label="경력 및 자기소개" labelCol={{style: {fontWeight: "bold"}}}>
                                            {record.career.map((item: any, index: any) => (
                                                <Form.Item key={index} label=""
                                                           labelCol={{style: {fontWeight: 'bold'}}}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <TextArea value={item.name} readOnly
                                                                  autoSize={{minRows: 3, maxRows: 6}}
                                                                  style={{flex: 1}}/>
                                                        <Button onClick={() => copyToClipboard(item.name)}
                                                                style={{marginLeft: '8px'}}>
                                                            복사
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            ))}
                                        </Form.Item>
                                    </Form>
                                </FormColumn>
                                <FormColumn>
                                    <Form layout="vertical" initialValues={{remember: true}}>
                                        <Form.Item label="사진자료" labelCol={{style: {fontWeight: 'bold'}}}>
                                            <Button type="primary" onClick={() => downloadAllFiles(record)}>모든 파일
                                                다운로드</Button>
                                        </Form.Item>
                                        <Form.Item label="소속" labelCol={{style: {fontWeight: "bold"}}}>
                                            {record.profile.map((item: any, index: number) => (
                                                <CustomFormItem
                                                    key={index}
                                                    label=""
                                                    value={item.name}
                                                    onCopy={() => copyToClipboard(item.name)}
                                                />
                                            ))}
                                        </Form.Item>
                                        <Form.Item label="SNS" labelCol={{style: {fontWeight: "bold"}}}>
                                            {record.sns.map((item: any, index: number) => (
                                                <CustomFormItem
                                                    key={index}
                                                    label=""
                                                    value={item.name}
                                                    onCopy={() => copyToClipboard(item.name)}
                                                />
                                            ))}
                                        </Form.Item>
                                        <Form.Item label="동영상링크" labelCol={{style: {fontWeight: "bold"}}}>
                                            {record.link.map((item: any, index: number) => (
                                                <CustomFormItem
                                                    key={index}
                                                    label=""
                                                    value={item.name}
                                                    onCopy={() => copyToClipboard(item.name)}
                                                />
                                            ))}
                                        </Form.Item>
                                    </Form>
                                </FormColumn>
                            </FormWrapper>

                        ),
                        expandedRowKeys: expandedRowKeys,
                        onExpand: (expanded, record) => handleExpand(record.id),
                        expandIconColumnIndex: -1
                    }}
                />
            </TableContainer>

        </CardContainer>

    );
};

export default TableComponent;
