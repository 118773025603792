import styled from "styled-components";

const Nav = styled.div``;

export const DesktopMenu = styled.nav`
  display: flex;
  background-color: #ffffff;
  align-items: center;
  text-align: center;
  padding: 0.5rem 2rem;
  max-width: 100%;
  height: 15%;
  gap: 3rem;
  font-size: 1rem;
  position: relative;
  //box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  font-size: 14px;

  a:first-child {
    margin-right: auto;
    &:hover {
      text-decoration: none;
    }
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export default Nav;