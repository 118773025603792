import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button } from 'antd';

const LinkDownloadTest: React.FC = () => {
    const handleDownload = async () => {
        const urls = [
            '/https://mediprofile-request.s3.ap-northeast-2.amazonaws.com/3fbc609e9edb4e331061bb2ed5e5133a.jpg975487e0-bc17-4cc8-a222-e87754e2099c',
            '/https://mediprofile-request.s3.ap-northeast-2.amazonaws.com/3fbc609e9edb4e331061bb2ed5e5133a.jpg975487e0-bc17-4cc8-a222-e87754e2099c',
        ];

        const zip = new JSZip();
        const imgFolder = zip.folder("images");

        const fetchImageAsBlob = async (url: string) => {
            const response = await fetch(url);
            return await response.blob();
        };

        const addImagesToZip = async () => {
            await Promise.all(urls.map(async (url, index) => {
                const blob = await fetchImageAsBlob(url);
                imgFolder?.file(`image${index + 1}.jpg`, blob);
            }));
        };

        await addImagesToZip();

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "images.zip");
        });
    };

    return (
        <Button onClick={handleDownload}>
            이미지 다운로드
        </Button>
    );
};

export default LinkDownloadTest;