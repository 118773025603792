import React, {useState} from "react";
import {Modal, Radio, notification, Typography} from "antd";

const {Text, Paragraph} = Typography;

interface PrivacyConsentModalProps {
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
}

const PrivacyConsentModal: React.FC<PrivacyConsentModalProps> = ({visible, onOk, onCancel}) => {
    const [consent, setConsent] = useState<string | null>(null);

    const handleOk = () => {
        if (consent === "agree") {
            onOk();
        } else {
            notification.error({
                message: '개인정보 보호 동의에 체크해 주세요',
                placement: 'bottom',
                duration: 2
            });
        }
    };

    const handleConsentChange = (e: any) => {
        setConsent(e.target.value);
    };

    return (
        <Modal
            title="개인정보 수집 및 이용에 대한 동의"
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
            okText="동의하고 제출"
            cancelText="취소"
            width={600} // 모달 너비 조정
        >
            <Paragraph>
                <Text></Text>
            </Paragraph>
            {/*<Paragraph>*/}
            {/*    <Text strong>개인정보 수집 및 이용에 대한 동의</Text>*/}
            {/*</Paragraph>*/}
            <Paragraph>
                <Text>1. 수집 및 이용의 목적: 메디프로필 제작</Text>
            </Paragraph>
            <Paragraph>
                <Text>2. 수집하는 개인정보의 항목: 이름(한글, 영문), 휴대전화, 이메일, 소속, 경력 및 자기소개, 사진, 명함, 기타 자료(개인 자료, SNS, 동영상 등)</Text>
            </Paragraph>
            <Paragraph>
                <Text>3. 개인정보의 보유 및 이용 기간: 메디프로필 사용 종료 시</Text>
            </Paragraph>
            <Paragraph>
                <Text>본 개인정보 수집 및 활용에 동의하지 않으시면 서비스 제공이 제한됩니다.</Text>
            </Paragraph>
            <Radio.Group onChange={handleConsentChange}>
                <Radio value="agree">동의함</Radio>
                <Radio value="disagree">동의하지 않음</Radio>
            </Radio.Group>
        </Modal>
    );
};

export default PrivacyConsentModal;
